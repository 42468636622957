.marginTopClass {
  margin-top: 1.2rem !important;
}

.contact_form_input {
  display: flex;
  justify-content: space-between;
}
.contactMobile {
  background-image: url("/src/Componenets/assets//images/neepHelpBgMobile.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact_form_main {
  background: #fff !important;
  padding: 30px 30px;
  width: 80%;
}
.top_dropdwon {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem !important;
  /* margin: 0!important; */
}
.form-con {
  z-index: 0 !important;
  /* width: 48%; */
}
.contact-title {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  color: #ffffff;
}
.checkbox_icon {
  font-size: 40px !important;
}

.successWrapper {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 9px rgba(192, 192, 192, 0.13);
  border-radius: 12px;
  box-sizing: border-box;
  width: 326px;
  height: 310px;
  padding: 40px 40px 0 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.circleBlueDiv {
  width: 85px;
  height: 85px;
  background: #25abdf;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.circleBlueDiv > div {
  width: 32.77px;
  height: 21.85px;
}
.circleBlueDiv > div > img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
}
.successTitleText {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;

  text-align: center;
  margin: 0;

  color: #263034;
}
.successsubTitleText {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #3a3a3a;
  margin: 0 auto;
  margin-top: -10px !important;
}
.successBtnClass {
  background: #25abdf;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
  width: 138px;
  height: 51px;
  border: none;
  outline: none;
  margin: 0 auto;
  color: #ffffff;
}
#demo-simple-select {
  color: #000 !important;
}
@media only screen and (max-width: 600px) {
  .top_dropdwon {
    padding: 0rem !important;
  }
  .rowStyleing {
    padding-right: 0 !important;
  }
  .rowStyleingleft {
    padding-left: 0 !important;
  }
  .rowStyling1 {
    padding: 0 !important;
  }
  /* .MuiFormControlLabel-label{
  font-size: 8px !important;
  margin-right: 0!important;
  padding-right: 0!important;
  
} */
  .MuiFormControlLabel-label {
    font-size: 11px !important;
    margin-right: 0 !important;
  }
  .MuiFormControlLabel-root {
    margin-right: 2px !important;
  }
  .marginTopClass {
    margin-top: 1.3rem !important;
  }
  .mobileMarginCls {
    margin-top: 15px !important;
  }
}
