@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&family=IBM+Plex+Sans:wght@200&family=Montserrat:wght@500&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600;1,700&display=swap");
.ambulatoryBgClass {
  width: 100%;
  height: 100vh;
  /* background-image: url("../assets/images/ambulatoryBgImageWeb.png"); */
  background-image: url("../assets/images/page2webbgdesktopblur.jpg");

  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.ambulatoryMobileBgClass {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/ambulatoryMobileBg.jpg");
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-attachment: scroll;

  background-repeat: no-repeat;
}
.highlightedText {
  /* background-color: rgba(255, 255, 255, 0.4); */
}
.spdrugBgClass {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/spdrugWebBg.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.spdrugMobileBgClass {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/SpecialDrugMobileBg.jpg");
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-attachment: scroll;
  background-repeat: no-repeat;
}
.compoundingBgClass {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/CompoundingBgWeb.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.compoundingMobileBgClass {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/compoundingMobilebg.jpg");
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-attachment: scroll;
  background-repeat: no-repeat;
}
.homeInfBgClass {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/HomeInfBgWeb.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.HomeInfMobileBgClass {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/HomeInfMobileBg.jpg");
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-attachment: scroll;
  background-repeat: no-repeat;
}
.WellnwssMobileBgClass {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/wellnessMobileBgg.jpg");
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-attachment: scroll;

  background-repeat: no-repeat;
}
.section6bannermobilebg {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/section6mobileimagebg.js.jpg");
  background-position: right;
  background-size: cover;
  /* background-attachment: fixed; */
  background-attachment: scroll;

  background-repeat: no-repeat;
}
.services1_main {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/page2webbgdesktopblur.jpg");
  background-position: top;
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}
.services1-wellness {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/ambulatorybg.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.services2_main {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/news2.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.services3_main {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/news3.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.services4_main {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/compoundingHomeBgWeb.png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
/* .services5_main {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/page5webbgblur.jpg");
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
} */
.services6_main {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/section6bgbannerimageweb.jpg");
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.services5-wellness {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/wellnessWebBg\ \(1\).png");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.services6bannerbg {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/section6bgbannerimageweb.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.services5-mobile {
  width: 100%;
  /* height: 625px; */
  height: 100vh;

  /* background-image: url("../assets/images/wellness_mobile_FinalMobile.jpg"); */
  background-image: url("../assets/images/mobPage5.jpg");

  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}
.services6-mobile {
  width: 100%;
  /* height: 625px; */
  height: 100vh;

  /* background-image: url("../assets/images/wellness_mobile_FinalMobile.jpg"); */
  background-image: url("../assets/images/section6mobileimagebg.js.jpg");

  background-position: right;
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}
.services_data {
  width: 85%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.services_data_services {
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.head {
  font-family: "Poppins";
  font-style: Regular;
  font-size: 16px;
  line-height: 24px;
  color: #25abdf;
}
.services_heading {
  font-family: objektiv-mk1;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  color: white;
  border-bottom: 1px solid white;
  width: fit-content;
  text-transform: uppercase;
}
.services1-heading-wellness {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 69px;
  text-transform: uppercase;

  /* Primary blue */

  color: #25abdf;
}
.services1-para-wellness {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #555555;

  max-width: 588px;
  width: 100%;
}
.services1-btn-wellness {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 30px;
  width: 214px;
  height: 51px;
  background: #fe0114;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
}
.servicesBtnClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 30px;
  width: 224px;
  height: 51px;
  background: #fe0114;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
}

.services_heading-wellness {
  font-family: objektiv-mk1;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  color: #25abdf;
  width: fit-content;
  text-transform: uppercase;
}
.services_para {
  font-family: "Poppins";
  font-style: Regular;
  font-size: 18px;
  line-height: 28px;
  color: white;
  max-width: 635px;
  width: 100%;
}
.services_para-wellness {
  font-family: "Poppins";
  font-style: Regular;
  font-size: 18px;
  line-height: 28px;
  color: #555555;
  max-width: 635px;
  width: 100%;
}
.services_btn {
  font-family: "Poppins";
  font-style: Regular;
  font-size: 16px;
  line-height: 24px;
  max-width: 340px;
  width: 100%;
  border: none;
  outline: none;
  padding: 10px 0px;
  background-color: #fe0114;
  color: white;
  box-shadow: 0px 0px 11px rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  height: 51px;
}
.services-btn-wellness {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 30px;
  width: 214px;
  height: 51px;
  background: #fe0114;
  border-radius: 5px;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.services_headingClass {
  font-family: objektiv-mk1;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  color: #25abdf;
  width: fit-content;
}
.services_heading1 {
  font-family: objektiv-mk1;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  color: #25abdf;
  border-bottom: 1px solid #25abdf;
  width: fit-content;
  text-transform: uppercase;
}
.services_para1 {
  font-family: "Poppins";
  font-size: 18px;
  line-height: 28px;
  font-style: Regular;
  color: #555555;
  max-width: 635px;
  width: 100%;
}
.services_btn1 {
  font-family: "Poppins";
  font-style: Regular;
  font-size: 16px;
  line-height: 24px;
  max-width: 340px;
  width: 100%;
  border: none;
  outline: none;
  padding: 10px 0px;
  background-color: #fe0114;
  color: white;
  box-shadow: 0px 0px 11px rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  height: 51px;
}
@media only screen and (max-width: 600px) {
  .services1_main {
    /* background-image: url("../assets/images/2.png"); */
    background-image: url("../assets/images/mobPage2.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
  }
  .services2_main {
    background-image: url("../assets/images/mobPage3.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
  }
  .services3_main {
    background-image: url("../assets/images/mobpage4.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
  }
  .services4_main {
    background-image: url("../assets/images/finalsm4.png");
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
  }
  /* .services5_main {
    background-image: url("../assets/images/wellness_mobile_FinalMobile.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
  } */

  .services_heading {
    font-weight: 500;
    font-size: 36px;
    line-height: 52px;
  }
  .services_para {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 27px !important;
  }
  .services_heading1 {
    font-weight: 500;
    font-size: 36px !important;
    line-height: 52px !important;
  }
  .services_para1 {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 27px !important;
  }
  .services_btn {
    font-size: 14px;
    line-height: 21px;
  }
  .services_btn1 {
    font-size: 14px;
    line-height: 21px;
  }

  .services_headingClass {
    font-size: 36px;
  }
}
@media only screen and (max-width: 450px) {
  .highlightedText {
    /* background-color: rgba(255, 255, 255, 0.4); */
    font-size: 26px;
    font-weight: 700;
  }
  .services_heading {
    font-size: 30px;
    line-height: 45px;
  }
  .services_heading1 {
    font-size: 30px !important;
    line-height: 45px !important;
  }
}
@media only screen and (max-width: 380px) {
  .highlightedText {
    /* background-color: rgba(255, 255, 255, 0.4); */
    font-size: 23px;
    font-weight: 700;
  }
  .services_heading {
    font-size: 25px;
    line-height: 42px;
  }
  .services_para {
    font-size: 15px;
    line-height: 22px;
  }
  .services_heading1 {
    font-size: 25px !important;
    line-height: 42px !important;
  }
  .services_para1 {
    font-size: 15px;
    line-height: 22px;
  }
  .services_btn {
    font-size: 13px;
    line-height: 24px;
    width: 280px;
  }
  .services_btn1 {
    font-size: 13px;
    line-height: 24px;
    width: 280px;
  }
  .me-5 {
    margin-right: 2rem !important;
  }
}
.special-div {
  display: flex;
  width: 300px;
  height: 26px;
  padding: 15px;
  background: rgba(39, 49, 53, 0.4);
  border-radius: 18px;
  justify-content: center;
  align-items: center;
}
.special-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0 !important;
  margin-left: 4px !important;
  color: #ffffff;
}
/* @media only screen and (max-width: 353px) {
  .highlightedText {
    background-color: rgba(255, 255, 255, 0.4);
    font-size: 21px;
  }
} */
