.payer_main{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/images/payerbackmain1.png');
    background-position: center;
    background-attachment: fixed;
    background-size: cover ;
    background-repeat: no-repeat;
    z-index: -1;
    margin-bottom: 20px;
}
.payer_heading{
width: 85%;
margin: 0px auto !important;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  text-transform: capitalize;
}
.payer_title{
    font-family: objektiv-mk1;
   font-style: normal;
font-size:48px ;
font-weight:700 ;
line-height:70px ;
color: #25ABDF;
text-transform: uppercase;
}
.payer_para{
    font-family: "Poppins";
    font-size:18px ;
    font-weight:400 ;
    line-height:27px ;
    color:  #555555;
    max-width: 734px;
    width: 100%;
}
.payer_btn{
    font-family: "Poppins";
    font-size:14px ;
    font-weight:500 ;
    line-height:21px ;
color: white;
padding: 15px 30px;
width: 214px;
background: #25ABDF;
border-radius: 5px;
 border: none;
 outline: none;
 border-radius: 5px;
 width: 190px;
 height: 51px;
}
.network_div{
    width: 100%;
    margin-top: 2%;
}
.network_data{
    width: 85%;
    margin: 0px 200px;
}
.network_heading{
    font-family: objektiv-mk1;
font-style: normal;
font-weight: 700;
font-size: 42px;
line-height: 60px;
text-transform: capitalize;
color: #3A3A3A;


}
.network_para{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;
color: #6A7C8A;
max-width: 885px;
width: 100%;
}
.payer_sponsor{
    padding: 20px 20px;
}
.sponsorDiv{
    display: flex !important;
    justify-content: center;
    align-items: center;
 
    width: 100%;
    margin: 0px auto;
}
.sponosr_logo{
    max-width: 350px;
    width: 100%;
    height: 192px;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    box-shadow: 0px 4px 20px rgba(74, 74, 74, 0.05);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 20px;
    margin: 9px 15px;
}
.sponosr_logo img{
    max-width: 294px;
    width: 100%;
height: 65px;
}
.sponosr_logo p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #555555;
    margin-top: 5px;
    margin-top: 15px;
}
@media only screen and (max-width: 1024px){
    .sponosr_logo p{
      
        font-size: 13px;
        line-height: 22px;
        
    }
    .sponosr_logo{
height: auto;
padding: 15px 20px;
    }
}

@media only screen and (max-width: 767px){
    .sponsor_div{
        flex-wrap: wrap;
            }
            .sponosr_logo p{
      
             
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #555555;
        
            }
}


@media only screen and (max-width: 600px){
    .payer_title{
    font-size:30px ;
    
    line-height:40px ;
 
    }
    .payer_para{
        
        font-size:15px ;
        
        line-height:21px ;
        
    }
    .payer_heading {  
        height: 95vh;
        width: 90%;
    }
    .payer_btn1 {
        
        margin-left: 0px;
        margin-top: 15px;
    }
    .payer_btn_div{

        display: flex;
        flex-direction: column;
    }
    .network_heading{
       
        font-size: 42px;
        line-height: 60px;
    
    }
    .network_para{
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
    max-width: 338px;
    width: 100%;
    }
    .network_data{
     padding: 10px 20px;
     width: 100%;
     margin: 0 !important;
     
    }
    .sponsorDiv{
        flex-direction: column;
    }
    .sponosr_logo{
        padding: 20px 20px;
    }
    .sponosr_logo p{
        font-weight: 500;
font-size: 18px;
line-height: 27px;
      
            }
    
    .sponosr_logo img {
       width: 95%;
    padding: 0 auto;
    margin: 0 auto;
    }
    .p-textmargin{
        margin-left: 15px;
    }
    .payer_main{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/images/payersMobileBg.png');
    background-position: center;
    background-attachment: scroll;
    background-size: cover ;
    background-repeat: no-repeat;
    z-index: -1;
    margin-bottom: 20px;
}
}
@media only screen and (max-width: 420px){
    .sponosr_logo {
        max-width: 100%;
        width: 100%;
        height: auto;
    
    
    }
 
    
    }
