.dialog-img-style {
  background: #d9d9d9;
  border-radius: 5px;
  width: 172.51px;
  height: 183.21px;
  cursor: pointer;
  margin-right: 12px;
}
.helper-para{
  font-size: 12px;
  margin-top: -10px;
}
.helper-para span{
  font-weight: 900;
}
.dialog-img-Mobile {
  background: #d9d9d9;
  border-radius: 5px;
  width: 135.85px;
  height: 143.75px;
  cursor: pointer;
}
.dialogImage-Xsm {
  background: #d9d9d9;
  border-radius: 5px;
  width: 115.85px;
  height: 120.75px;
  cursor: pointer;
}
.goBackBnt {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 14px;
  border-bottom: 1px solid #3a3a3a;
  width: 85.25px;
  height: 24px;
  cursor: pointer;
  padding-bottom: 6px;
}
.backIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.goBackText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3a3a3a;
}
.mobileBackBtnDiv {
  height: 42px;
  width: 42px;
  background-color: #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.app-active {
  background: #25abdf !important;
  color: white !important;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}
.form-sidebar:hover {
  cursor: pointer;
}
.form-bar {
  border-left: 2px solid #25abdf;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: -1px !important;
}
.form-data {
  padding: 35px 65px;
  border: 1px solid #25abdf;
  border-radius: 0px 12px 12px 12px;
  /* opacity: 1; */
  background-color: rgb(234, 242, 245, 0.3);
}

.sidesvg-div {
  width: 62px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: -1px;
}
.sidetext-div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3a3a3a;
  width: 100%;
}

.appactivetext {
  border-bottom: 1px solid #3a3a3a;
}
.AppTitle {
  font-size: 24px;
  font-size: 500;
  line-height: 35px;
  font-family: Objektiv-Mk1;
  margin-top: 6px;
  margin-bottom: 20px;
  color: #3a3a3a;
}
.contact_form_input {
  display: flex;
  justify-content: space-between;
}

.contact_form_main {
  background: #fff !important;
  padding: 30px 30px;
  width: 80%;
  border-radius: 12px;
}
.radio-div {
  /* width: 80%; */
  justify-content: space-between;
  margin-top: -20px !important;
}
.radio-element {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3a3a3a;
}
.top_dropdwon {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem !important;
}

.react-calendar__tile--active {
  background: #25abdf !important;
  border-radius: 50% !important;
  color: white !important;
}
.react-calendar {
  border: none !important;
  width: 85% !important;
}
.app-p-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "poppins";
}
.appContactBtn {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: white !important;
  font-family: "poppins";
  height: 51px;
  width: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.hr-styling {
  width: 100%;
  height: 3px !important;
  background-color: #25abdf;
}
.hr-styling2 {
  width: 50%;
  height: 3px !important;
  background-color: gainsboro;
}
.hr-styling1 {
  width: 50%;
  height: 3px !important;
  background-color: #25abdf;
}
.Step1Btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 30px;
  height: 51px;
  width: 100px;
  background: #eaf2f5 !important;
  border-radius: 5px;
  color: #25abdf !important;
  border: none;
  outline: none;
}
.disableStep1Btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 30px;
  height: 51px;
  width: 100px;
  background: rgb(229, 229, 229) !important;
  border-radius: 5px;
  color: white !important;
  border: none;
  outline: none;
}

.app-top-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  color: #6a7c8a;
  max-width: 882px;
}

@media only screen and (max-width: 768px) {
  .radio-div-style {
    margin: 0 !important;
    margin-top: 30px !important;
  }

  .radio-div {
    width: 100%;
    /* justify-content: space-between; */
    margin-top: 0 !important;
  }
  .radio-element {
    width: 100%;
  }
  .form-data {
    width: 90%;
    margin: 0 auto;
    padding: 25px 17px;
    border-radius: 12px;
    height: 50vh;
    overflow-y: hidden;
  }
  .top_dropdwon {
    padding: 0rem !important;
  }
  .form-bar {
    border: none !important;
    display: flex;
    margin-top: 0px !important;
    width: 90%;
    margin: 0 auto !important;
    align-items: center;
    justify-content: center;
  }
  .form-sidebar {
    width: 116px;
    height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
  }
  /* .form-bar{
    width: 100%;
  } */
  .active {
    border-radius: 5px 5px 0 0;
  }

  .time-custom {
    margin-top: 10px !important;
  }
  .container {
    max-width: 100% !important;
  }
}

/* @media only screen and (max-width: 480px){
  .form-icon{
  margin-left: 30% !important
  }

} */
