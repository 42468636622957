.active {
  color: #25abdf !important;
  font-weight: 600 !important;
}
.active:hover {
  color: #25abdf !important;
  text-decoration: underline !important;
}
.providerText:hover {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: black;
  /* text-decoration: underline !important; */
}
.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 3333;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 80.19%
  );
  filter: drop-shadow(0px 4px 27px rgba(114, 114, 114, 0.08));
}
.languageSelect {
  width: 40px !important;
}
.paySelect {
  width: 105px;
}

.whitePhonediv {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whitePhonediv > img {
  width: 100%;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("../assets/images/mobile.png") !important;
}
.saerch_mob {
  background-color: #25abdf;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  height: 34px;
  justify-content: space-between;
}
.saerch_mob1 {
  display: none;
}
.search_input {
  border: none;
  outline: none;
  background-color: #25abdf;
  border-bottom: 1px solid white;
  margin-left: 10px;
}
.saerch_mob > ::placeholder {
  color: white !important;
}
.navbar-light .navbar-toggler-icon1 {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
  background-image: url("../assets/images/Cancel.png") !important;
}

.mob_fone {
  display: none;
}
.header_new {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  background-color: #25abdf;
  position: fixed;
  z-index: 44444;
  padding: 3px 7%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: white;
  gap: 25px;
}
.header_new1 {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  background-color: #25abdf;
  position: fixed;
  z-index: 44444;
  padding: 3px 7%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: white;
  gap: 25px;
  display: none;
}
.header_new2 {
  display: none;
}
.header_new p {
  margin-bottom: 0px !important;
}
.navdropdpwn {
  border: none !important;
  border-bottom: 1px solid black;
  outline: none;
  background-color: transparent;
  /* margin-left: 10px; */
  color: white;
}
.navdropdpwn option {
  color: black;
}

.nav_logo {
  max-width: 220px;
  height: auto;
  object-fit: contain;
  top: -280%;
  position: absolute;
}

.navbar-brand {
  padding-left: 30px !important;
}

.nav_data {
  display: flex;
  margin-right: 30px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: black !important;
}
.linee1:hover {
  /* text-decoration: underline; */
}
.linee2:hover {
  /* text-decoration: underline; */
  color: "#000";
}
.container-fluid {
  width: 90%;
}

.nav-link {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-top: 10px;
  color: black;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}
/* .nav-link :active {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #c10944;
    font-size: 16px;
    background-color: #61dafb;
  } */
.hader_icon {
  margin-left: 10px !important;
}
.banner-txt {
  cursor: pointer;
}
.dropdown {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.menu-items {
  display: block;
  padding: 12px 16px;
  text-decoration: none;
  color: black;
  font-size: 14px;
}

.menu-items:hover {
  background-color: #f1f1f1;
}

.linee3:hover .dropdown {
  display: block;
}

.linee2 .dropdown.show {
  display: block;
}
.linee3 span {
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
}

.linee3 span:hover {
  text-decoration: underline;
}
.linee3 {
  padding: 10px 16px;
  /* margin-top: 10px; */
}
/* .accordion-body {
  background: transparent !important;
} */
.remove-border .accordion-item {
  border-bottom: none !important;
  margin-top: -18%;
}
.remove-border {
  height: 100px;
}
.custom-accordion-body {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 145px;
  background: transparent !important;
}
/* .accordion-button::after {
  margin-top: 9%;
} */

.custom-nav-link {
  padding: 0 !important;
  margin: 0 !important;
}
/* .ourService {
  margin-top: 25%;
} */
/* .refferal {
  margin-top: -25%;
}
.Submitrefferal {
  margin-top: -17%;
} */
#accordionExample {
  /* height: 100px; Adjust the height as needed */
  /* overflow-y: auto; Add scrollbar if necessary */
}
.customAccordionHeader {
  margin-bottom: -10%;
}
@media only screen and (max-width: 1044px) {
  .container-fluid {
    width: 100%;
  }
  .nav-link {
    font-size: 16px;
  }
  .linee3 span {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .nav-link {
    font-size: 15px;
    padding: 10px 7px;
  }
}
@media only screen and (max-width: 767px) {
  /* .accordion-button::after {
    margin-top: 4%;
  } */
  .linee3 {
    padding: 12px 29px;
    /* margin-top: 10px; */
  }
  .buttonHeight {
    height: 55px;
    margin-left: -8px;
    margin-bottom: -14px;
  }
  .custom-accordion-body {
    position: relative;
    left: 8%;
    margin-top: 3%;
    width: auto;
  }
  .nav-link {
    font-size: 18px;
    padding: 10px 30px;
  }
  .mob_fone {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    margin-top: 5px;
  }
  .navbar-collapse {
    height: auto;
    background-color: white;
    padding-bottom: 25px;
  }

  .nav_data {
    flex-direction: column;
  }

  .navbar-toggler {
    margin-top: 5px;
    border: none !important;
  }
  .navbar {
    /* background-color: white !important; */
  }
  .navbar-brand {
    padding-left: 0px !important;
  }
  .nav_logo {
    width: 136px;
    height: 37px;
    object-fit: contain;
    padding-left: 20px;
    position: relative;
  }
  .container-fluid {
    width: 100% !important;
    padding: 0px !important;
  }
  .navbar-light .navbar-toggler {
    color: none !important;
    border: 0px solid black !important;
    padding-right: 24px !important;
  }
  .header_new {
    display: none;
  }
  .header_new1 {
    display: flex;
  }
}
.providerText {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: black;
}
@media only screen and (max-width: 600px) {
  .header_new {
    padding: 3px 6%;
    gap: 18px;
    justify-content: flex-start;
  }
  .banner-txt {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 440px) {
  .openMargin {
    margin-bottom: 0%;
  }
  .closedMargin {
    margin-bottom: 5%;
  }
  .navbarAlignment {
    margin-bottom: -3%;
  }

  .custom-accordion-body {
    position: relative;
    left: 8%;
    margin-top: 3%;
    width: auto;
  }
  .customAccordionHeader {
    margin-bottom: -4%;
  }
  .mobileMarginFix {
    margin-top: -25% !important;
  }
  #accordionExample {
    height: auto;
    /* overflow-y: auto; Add scrollbar if necessary */
  }
  .ourService {
    margin-top: 0%;
  }
  .refferal {
    margin-top: 0%;
  }
  .Submitrefferal {
    margin-top: 0%;
  }
  .remove-border .accordion-item {
    border-bottom: none !important;
    margin-top: 0%;
  }
  .accordion-collapse {
    margin-top: 2%;
  }
  .banner-txt {
    font-weight: 400;
    font-size: 10px;
  }
  .navdropdpwn {
    font-size: 10px;
  }
}
@media only screen and (max-width: 390px) {
  .navbar-brand img {
    width: 120px;
  }

  .navbar-toggler {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 360px) {
  .nav-link {
    font-size: 16px;
    padding: 5px 10px;
    line-height: 22px;
    text-align: center;
  }
  .banner-txt {
    font-weight: 400;
    font-size: 9px;
  }
  .navdropdpwn {
    font-size: 9px;
  }
  .header_new {
    padding: 3px 5%;
    gap: 10px;
  }
}
