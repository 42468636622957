.appointment-banner{
    width: 100%;
    height: 277px;
    background-image: url('../assets/images/appointment-banner.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
display: flex;
justify-content: center;
align-items: center;
}
.appointment-title{
    font-family: Objektiv-Mk1;
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 69px;
text-transform: uppercase;
display: inline-block;
margin-top: 85px;
color: #FFFFFF;
}
@media only screen and (max-width: 600px){
    .appointment-title{
font-size: 36px;
line-height: 45px;
margin-left: 20px;
margin-top: 55px; 
}
     .navbar-light .navbar-toggler {
    color: none !important;
    border:0px solid black !important;
}
}