.wrapper-wellness{
    top:-50px;
}
.grey-div{
width: 378px;
height: 395px;
/* background: #D9D9D9; */
z-index: 9;
position: relative;
right: -5px;
top:30px;
}
.divs-image{
 width: 100%;
 object-fit: cover;   
 height: 100%;
}
.blue-div{
width: 555px;
height: 465px;
background: #25ABDF;
color: white;
display: flex;
flex-direction: column;
padding: 60px 35px;
z-index: 19;
gap: 1rem;
}
.white-line{
    display: inline-block;
width: 205px;
height: 0px;
border-top: 2px solid #FFFFFF;
}
.blue-div-title{
    font-family: Objektiv-Mk1;
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 52px;
text-transform: uppercase;

/* White */

color: #FFFFFF;
}
.blue-div-subtitle{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;
color: #FFFFFF;
}
@media only screen and (max-width: 1024px){
    .blue-div-title{
        font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    }
}

@media (min-width: 320px) and (max-width: 768px) {
 .wrapper-wellness{
 top:-70px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 } 
 .grey-div{
    max-width: 329px;
height: 297px;
right: 0px;
width: 100%;
 }
.blue-div{
max-width: 388px;
width: 100%;
height: auto;
padding: 35px 30px;
}
.blue-div-title{
    font-weight: 500;
font-size: 24px;
line-height: 35px;
}
 
}