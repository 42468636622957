* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Objektiv-Mk1;
}
a {
  text-decoration: none;
  color: none !important;
}
a {
  color: inherit;
}
a:hover {
  color: inherit !important;
}
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+KR&family=IBM+Plex+Sans:wght@200&family=Montserrat:wght@500&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600;1,700&display=swap");

:root {
  --contact-btn-bg: #674bc0;
  --white: #ffffff;
  --white-light: #f5f5f5;
  --title-bg: #323232;
  --contact-bg: #25abdf;
  --title: #000000;
  --footer-title: #3a3a3a;
  --footer-link: #6a7c8a;
}
.newfooter_pics {
  display: flex;
  justify-content: space-between;
  width: 145px;
  margin: 10px 0px;
}
.img_logo_footer {
  object-fit: contain;
  width: 50%;
}

.footer {
  background-color: var(--white);
}

.footer_text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.footer_text2 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.footer_btn {
  background-color: var(--contact-btn-bg) !important;
  border: none !important;
}
.footer_link_heading {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--footer-title);
  margin-top: 10px;
}
.footer_link_heading1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6a7c8a;
  margin-left: 5px;
  margin-top: 10px;
}
.footer_link {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif;
  line-height: 21px !important;
  color: var(--footer-link) !important;
  text-decoration: none !important;
  margin-bottom: 10px !important;
}
.footer_link1 {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif;
  line-height: 21px !important;
  color: white !important;
  text-decoration: none !important;
  margin-bottom: 10px !important;
  text-decoration: none;
}
.contact_option {
  border: 1px solid white !important;
}

.footer_Last {
  background-color: var(--contact-bg);
}
.testimonial_image_div {
  width: 60px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;
}

.testimonial_image {
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 10px;
}
.testimonial_name {
  font-weight: 700;
  font-size: 16px;
  line-height: 23.02px;
  font-family: "Poppins", sans-serif;
  color: #263034 !important;
}
.testimonial_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #6a7c8a;

  margin: -5px 0;
}
.testi_heading {
  font-family: objektiv-mk1;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 60px;
  text-transform: uppercase;
  color: #3a3a3a;
  margin-bottom: 0px;
}
.testimonial {
  background-color: var(--white);
  position: relative;
  width: 80%;
  margin: 0px auto;
}
.carousel .carousel-indicators {
  bottom: -20% !important;
}
.carousel .carousel-indicators button {
  background-color: gray !important;
  width: 4px !important;
  padding: 3px !important;
  border-radius: 50% !important;
}
.carousel .carousel-indicators .active {
  background-color: var(--contact-bg) !important;
}
.carousel-control-prev-icon {
  background-image: url("./Componenets/assets/images/Left\ arrow.png") !important;
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.53);
  z-index: 10 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  background-position: center;
  /* background-size: cover; */
}
.carousel-control-next-icon {
  background-image: url("./Componenets/assets/images/right\ arrow.png") !important;
  box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.53);
  z-index: 10 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  background-position: center;
}
.carousel-indicators {
  display: none !important;
}
.testi_div {
  margin: 5px 0;
  padding: 20px;

  margin: 0px auto !important ;
  text-align: center !important;
}
.new {
  margin: 0px auto !important;
  z-index: 0;
}

.contact {
  box-shadow: none !important;
  outline: none !important;
  background-image: url(".././src/Componenets/assets/images/contact-back.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact_form {
  background-color: var(--white);
  box-shadow: none !important;
  outline: none !important;
}
.ContactBtn {
  text-decoration: none;
  background-color: var(--contact-bg);
  padding: 15px 30px;
  border-radius: 5px;
  color: var(--white) !important;
  width: 111px;
  height: 51px;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.disableContactBtn {
  text-decoration: none;
  /* background-color: var(--contact-bg); */
  padding: 15px 30px;
  background: rgb(229, 229, 229) !important;
  border-radius: 5px;
  color: white !important;
  width: 111px;
  height: 51px;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom_color {
  color: var(--contact-bg);
}

.selectBox-border {
  border-color: red !important;
}

.contact_select {
  background-color: var(--white) !important;
  border: 1px solid white !important;
  color: var(--contact-bg) !important;
}

.contact_option {
  background-color: var(--white) !important;
  color: var(--contact-bg) !important;
  border: 1px solid white !important;
  box-shadow: none !important;
  outline: none;
}

.form-select {
  border: none;
  box-shadow: none !important;
  outline: none !important;
}
.footer_line {
  display: none;
  outline: none;
}
.footer_social_icon {
  width: 30px;
  height: 30px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #25abdf;
}
.bottom_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0px auto;
  margin-top: 20px !important;
}
.bottom_logo img {
  width: 103px;
  height: 59px;
  object-fit: contain;
}
.mob_select {
  border: none !important;
  outline: none !important  ;
  width: 90%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
}
.lagnuages {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-bottom: 0px;
}
.footer_link_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 33px;
}
.executive_mob {
  display: none;
}
.polices_mob {
  display: none;
}
.destop_vieew {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.mob_vieew {
  display: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.testimonial_para {
  width: 80%;
  margin: 15px auto;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #6a7c8a;
}
.new_Align {
  display: flex;
  align-items: center;
}
.new_Align1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.logo_img_div1 {
  display: flex;
  justify-content: flex-start;
  /* margin-top: 30px; */
}
.logo_img_div1 img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin: 0px 5px;
}
.logo_img_div {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 0px auto;
}
.logo_img_div img {
  object-fit: contain;
  height: 72px;
  width: 120px;
}
.footer_address {
  max-width: 255px;
  width: 100%;
  margin-left: 5px;
  float: right;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6a7c8a;
  padding-left: 5px;
}
.executive_destop {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.polices_destop {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.desktop_vieew {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .footer_link_heading {
    font-size: 16px;
    line-height: 22px;
  }
  .bottom_logo {
    width: 96%;
  }
  .mob_select {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .bottom_logo {
    flex-direction: column-reverse;
    width: 100%;
  }
  .logo_img_div1 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;
    padding-left: 0px;
    padding-top: 10px;
  }
  .logo_img_div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  .new_Align1 {
    justify-content: start;
  }
  .footer_line {
    margin: 10px 0px !important ;
    width: 100%;
    height: 1px;
    background-color: var(--white) !important;
    display: block;
  }
  .lagnuages {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    text-align: center;
  }
  .executive_destop {
    display: none;
  }
  .executive_mob {
    display: block;
  }
  .desktop_vieew {
    display: none;
  }
  .mob_vieew {
    display: block;
    text-align: left;
  }
  .polices_destop {
    display: none;
  }
  .polices_mob {
    display: block;
    margin-bottom: 12px;
  }
  .mob_select {
    font-size: 16px;
    line-height: 22px;
    margin: 6px 0px;
  }
  .accordion-body {
    text-align: left !important;
  }
}
@media only screen and (max-width: 600px) {
  .testimonial_para {
    width: 85%;

    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  .footer_address {
    max-width: 220px;
    width: 100%;
  }
  .testimonial_name {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .testimonial_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  .testimonial {
    width: 95% !important;
    margin: 0px auto;
  }

  .mob-footer_link {
    font-size: 16px !important ;
    line-height: 22px;
  }
  .mob-footer_link p {
    margin-bottom: 10px !important;
  }
  .testi_heading {
    font-weight: 900;
    font-size: 28px;
    line-height: 40px;
    color: #3a3a3a;
    margin-bottom: 0px;
  }
  .carousel-control-prev-icon {
    width: 40px !important;
    height: 40px !important;
    margin-left: -10px;
    z-index: 10 !important;
  }
  .carousel-control-next-icon {
    width: 40px !important;
    height: 40px !important;
    margin-right: -10px;
    z-index: 10 !important;
  }
}
@media only screen and (max-width: 420px) {
  .mob_select {
    font-size: 16px;
    line-height: 22px;
  }
  .footer_link_heading {
    font-size: 16px;
    line-height: 22px;
  }
}
.svgStyleDropDown {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  /* fill: currentColor; */
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 7px;
  top: calc(50% - 0.5em);
  pointer-events: none;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .map {
    margin-left: 20px;
  }
}

@media screen and (min-width: 540px) and (max-width: 768px) {
  .footerAdress {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
