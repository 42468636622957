.raferal_main{
background-image: url('../assets/images/five.jpg');
background-position: center;
background-repeat: no-repeat;
background-size: cover;

width: 100%;
height: 340px;
display: flex;
justify-content: center;
align-items: center;
}
.raferal_mainMobile{
/* background-image: url('../assets/images/refrealMobileBg.png'); */
    background-image: url('../assets/images/appointment-banner.png');

background-position: center;
background-repeat: no-repeat;
background-size: cover;

width: 100%;
height: 340px;
display: flex;
justify-content: center;
align-items: center;
}
.raferal_heading{
    font-family: objektiv-mk1;
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 69px;
margin-top: 30px;


text-transform: uppercase;

color: #FFFFFF;
}
.referal-p-style{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;

/* text */
width: 882px;

color: #6A7C8A;

}
.raferal_form_main{
    width: 100%;
    padding: 40px 0px;
}
.raferal_form_data{
    width: 85%;
    margin: 0px auto;
}
/* .raferal_form_data p{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;
width: 70%;
color: #555555;
} */
.raferal_form{
    width: 80%;
    margin: 0px auto;
    padding: 40px 10px;
    margin-top: 30PX;

}
/* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
   
    margin-bottom: 30px;
}
.css-1r7ruti-MuiFormControl-root {
    
    margin: 0px !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    margin-bottom: 28px;
   
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
background: #f6fafb !important;
    z-index: 2;
   
} */
.raferal_btn{
    width: 51px;
height: 21px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
background: #25ABDF;
border: none;
outline: none;
text-align: center;
color: #FFFFFF;
height: 51px;
width: 111px;
border-radius: 5px;

}
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 22px;
} */

/* .raferal_form_main .MuiFormControl-root {
    z-index: 0 !important;

} */
/* .raferal_form_main {
    z-index: 0 !important;

} */
/* .raferal_form_main  */
/* .MuiInputLabel-root{
    background-color: white !important;
} */
@media only screen and (max-width: 600px){
    .raferal_heading{
        font-size: 36px;
line-height: 52px;
padding: 15px 15px;
    }
    .referal-p-style{
       width: 100%;
        }
    .raferal_form {
        width: 90%;
        
        padding: 20px 10px;
    }
}

/* .MuiSelect-select .MuiSelect-outlined .MuiInputBase-input  */
.MuiSelect-select{
    color: rgba(58, 58, 58, 0.6)  !important;
}