.webBannerDiv{
    height: 100vh;
    width: 100vw;
    gap:1rem
}
.webBannerTitle{
    font-family: Objektiv-Mk1;
font-style: normal;
font-weight: 800;
font-size: 64px;
line-height: 92px;
/* identical to box height */

text-align: center;
text-transform: uppercase;

/* Primary blue */

color: #25ABDF;
}
.webBannerSubTitle{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
/* identical to box height */

text-align: center;
margin: 0 !important;

/* Grey 2 (banners text) */

color: #555555;
}
.banner-img-div{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bannerLogo{
    cursor: pointer;
}