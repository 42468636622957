.paybill_main{
    width: 100%;
}
.paybill_data{
    width: 85%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    
}
.paybill_heading p{
    font-family: Objektiv-Mk1;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 69px;
    text-transform: uppercase;
    color: #25ABDF;
    margin-top: 30px;
    
}
.paybill_images_div{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0px auto;
}
.paybill_images{
   
    width: 100%;
    margin: 10px 25px;
}
.paubill_col{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 600px){
    .paybill_images_div{
       flex-direction: column;
       justify-content: center;
       align-items: center;
       margin: 0px auto;
    }
    .paybill_heading p{
        font-weight: 700;
        font-size: 36px;
        line-height: 52px;
        margin-top: 5px;
        
    }
    .paubill_col{
        margin-top: 15px;
    }
}