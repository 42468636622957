.flexWrapper{
/* display: flex; */
/* background-image: url("../assets/images/flexWebBg.png");     */
padding: 100px 5px;
background-repeat: no-repeat;
    background-size: cover;
}
.ambulatoryFlexWebBgClass{
background-image: url("../assets/images/flexWebBg.png") !important;    
}
.spcldrugFlexWebBgClass{
background-image: url("../assets/images/spldrugFlexWebBg.png") !important;

}
.homeInfusionFlexWebBgClass{
background-image: url("../assets/images/homeInfFlexWebBg.png") !important;
}
.section6FlexWebBgClass{
background-image: url("../assets/images/section6Pointerbgweb.jpg") !important;
}
.section6FlexMobileBgClass{
    background-image: url("../assets/images/section6Pointerbgmobile.bin.jpg") !important;
 background-position: center;
  background-size: cover;
  background-repeat: no-repeat; 
}
.compoundingFlexWebBgClass{
background-image: url("../assets/images/compoundingFlexWebBg.png") !important;
}
.ambulatoryFlexMobileBgClass{
background-image: url("../assets/images/ambulatoryFlexMobileBg.png") !important;  
 background-position: center;
  background-size: cover;
  background-repeat: no-repeat;  
}
.spcldrugFlexMobileBgClass{
background-image: url("../assets/images/spDrugFlexMobileBg.png") !important;
 background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.homeInfusionFlexMobileBgClass{
background-image: url("../assets/images/homeInfFlexMobileBg.png") !important;
 background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.compoundingFlexMobileBgClass{
background-image: url("../assets/images/compoundingFlexMobileBg.png") !important;
 background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.flex-l-side{
flex: 1;
padding: 30px 5px;
}
.left-flex{
display: flex;
flex-direction: column;
gap: 0.5rem;
}
.left-flex-title{
    font-family: Objektiv-Mk1;
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 52px;
text-transform: capitalize;

/* White */

color: #FFFFFF;

}
.left-flex-subtitle{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 147.4%;
/* or 27px */


/* White */

color: #FFFFFF;
}
.ul-div{
    display: flex;
    flex-direction: column;
    /* gap: 0.1rem; */
}
.li-flex-div{
display: flex;
gap:2rem
}
.smallGreydivSvg{

}
.li-text{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 147.4%;
color: #FFFFFF;

}

.flex-r-side{
flex: 1;
display: flex;
justify-content: center;
align-items: center;
}

.flexRightImageStyle{
    max-width: 445px;
    width: 100%;

object-fit: center;
    border-radius: 12px;
}
@media (min-width: 320px) and (max-width: 768px) {
.mobileStyling{
    flex-direction: column;
}
}
