.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map {
  width: 100%;
  /* height: 60vh; */
  height: 25vh;
}

.pin {
  display: flex;
  align-items: center;
  /* width: 180px; */
  color: var(--main-blue);
  width: 50px;
  height: 50px;
}

.pin-icon {
  /* font-size: 4rem; */
  width: 100%;
  height: 100%;
}

.pin-text {
  font-size: 1.3em;
}

.gmnoprint {
  display: none;
}

.gm-style-cc {
  display: none !important;
  visibility: hidden;
  opacity: 0;
  z-index: 0;
}

.gmnoprint > div >a[href="https://www.google.com/intl/en-US_US/help/terms_maps.html"]
{
  display: none !important;
  visibility: hidden;
  opacity: 0;
  z-index: 0;
}

@media screen and (min-width: 799px) {
  .google-map {
    /* height: 30vh; */
    height: 195px;
    /* width: 195px; */

  }

  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    /* width: 5vw; */
    width: 40px;
    height: 40px;
  }

  .pin-icon {
    /* font-size: 5vw; */
    width: 100%;
    height: 100%;
  }
}

