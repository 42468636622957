.faaq_main {
  width: 100%;
  padding-bottom: 30px;
}
.pdfName:hover {
  background-color: rgb(219, 225, 229);
}
.fad_data {
  max-width: 1120px;
  width: 100%;
  margin: 0px auto;

  border-radius: 5px;
  padding: 20px 20px;
}
.referal-heading {
  font-family: objektiv-mk1, sans-serif;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  line-height: 70px;
  color: #25abdf;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}
.para {
  line-height: 1.8em;
  font-weight: 300;
}
.para-center {
  margin-top: 100px;
  line-height: 1.8em;
  font-weight: 600;
  text-align: center;
}
.content-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  align-items: center;
}
.para-head {
  width: 100%;
  padding-right: 20px;
}
.select-text {
  border: 1px solid #e0e0e0;
  border-top: #e0e0e0;
  padding: 8px 16px 16px;
  cursor: pointer !important;
}
.select-text:hover {
  background-color: #e0e0e0;
}
@media only screen and (max-width: 760px) {
  .flex-center {
    flex-direction: column;
  }
}
