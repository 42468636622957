.ourservices_main {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/ourservi.png");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.ourservices_heading {
  width: 85%;
  margin: 0px auto !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
}
.ourservices_title {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 69px;
  text-transform: uppercase;
  color: #25abdf;
}
.ourservices_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  max-width: 675px;
  width: 100%;
  color: #555555;
}
.ourservices_btn {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  border: none;
  outline: none;
  color: white;
  padding: 15px 30px;

  width: 214px;
  height: 51px;
  /* background: #25ABDF; */
  border-radius: 5px;
  background: #fe0114;
}
.ourservices_text {
  width: 85%;
  margin: 0px auto;
  margin-top: 60px;
}

.ourservices_text_heading {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 60px;
  text-transform: capitalize;

  color: #3a3a3a;
}
.ourservices_text_para {
  max-width: 885px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  color: #555555;
}
.servies_card {
  width: 85%;
  margin: 0px auto;
  display: flex;
}

.servies_card_data {
  max-width: 350px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eaf2f5;
  border-radius: 5px;
  padding: 15px 30px;
  margin-right: 40px;
  margin-top: 20px;
}
.servies_card_data1 {
  max-width: 350px;
  width: 100%;
  background: #ffffff;

  padding: 15px 20px;
  margin-right: 40px;
  /* margin-top: 20px; */
}
.services_card_heading {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #3a3a3a;
}
.services_card_para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 147.4%;
  color: #6a7c8a;
}
.services_card_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 10px auto; */
  justify-content: center;
  align-items: center;
  width: 129px;
  height: 41px;
  background: #25abdf;
  /* background: #FE0114; */

  color: white;
  border-radius: 5px;
  border: none !important;
  outline: none !important;
}
.services_card_btn span {
  margin-left: 5px;
}
@media only screen and (max-width: 991px) {
  .services_card_heading {
    font-size: 17px;
  }
  .services_card_para {
    font-size: 14px;
  }
  .services_card_btn {
    /* padding: 10px 15px; */
    /* width: 120px; */
    /* height: 41px; */
  }
  .servies_card_data {
    padding: 15px 10px;
    margin-right: 20px;
    /* margin-top: 20px; */
  }
}
@media only screen and (max-width: 700px) {
  .servies_card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 93%;
  }
  .servies_card_data {
    padding: 35px 25px;
    margin-right: 0px;
    /* margin-top: 20px; */
  }
  .services_card_heading {
    font-size: 20px;
  }
  .services_card_para {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .ourservices_title {
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
  }
  .ourservices_para {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }
  .ourservices_heading {
    height: 95vh;
    width: 93%;
  }

  .ourservices_btn_div {
    display: flex;
    flex-direction: column;
  }
  .ourservices_main {
    background-image: url("../assets/images/ourservicesmob.png");
    background-attachment: scroll;
  }

  .ourservices_text {
    width: 93%;
    margin-top: 50px;
  }

  .ourservices_text_heading {
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
  }
  .ourservices_text_para {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 380px) {
  .ourservice_title {
    font-size: 30px;
    line-height: 42px;
  }
}
