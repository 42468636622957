.SubmitBtn {
  background-color: #25abdf;
  border: none;
  border-radius: 5px;
  font-family: "Poppins";
  height: 51px;
  outline: none;
  padding: 15px 30px;
  text-decoration: none;
  /* width: 111px; */
  color: #fff;
}

.grid-title-p {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 60px;
  text-transform: capitalize;
  color: #3a3a3a;
}
.grid-title-span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #555555;
  width: 885px;
}
.grid-item-div {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-shadow: 0px 4px 20px rgba(74, 74, 74, 0.05);
  border-radius: 15px;
  height: 450px;
  width: 350px;
  padding: 30px 20px;
}
.grid-img {
  width: 142px;
  height: 243px;
  object-fit: contain;
  margin: auto;
}
.grid-p {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 37.41px;
  margin-bottom: 0 !important;
  color: #263034;
}
.grid-span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #555555;
}

.grid-btn {
  background: #25abdf;
  border-radius: 5px;
  width: 129px;
  height: 41px;
  padding: 10px 12px;
  display: flex;
  outline: none;
  border: none;
  color: white;
  gap: 9px;
  align-items: center;
  justify-content: center;
}

@media (min-width: 320px) and (max-width: 768px) {
  .grid-item-div {
    width: 100%;
    margin: auto;
  }
  .grid-title-p {
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
  }
  .grid-title-span {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    max-width: 388px;
    width: 100%;
  }
}
