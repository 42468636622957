.home_main {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/section6bgbannerimageweb.jpg");
  /* background-image:  url("../assets/images/news1.jpg"); */

  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}
.home_heading {
  width: 85%;
  margin: 0px auto !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
}
.home_title {
  font-family: objektiv-mk1;
  font-style: normal;
  font-size: 48px;
  font-weight: 700;
  line-height: 70px;
  color: #25abdf;
  text-transform: uppercase;
}
.home_para {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #555555;
}
.home_btn {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: white;
  background-color: #fe0114;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 6px 10px;
  width: 190px;
  height: 51px;
}
.home_btn1 {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: white;
  background-color: #25abdf;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 6px 10px;
  width: 190px;
  height: 51px;
  margin-left: 10px;
}
.highlightedTexts {
  /* background-color: rgba(255, 255, 255, 0.4); */
  /* text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.46); */
}

@media only screen and (max-width: 600px) {
  .home_title {
    max-width: 368px;
    width: 100%;
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
  }
  .home_para {
    display: none;
  }
  .home_heading {
    padding-left: 20px;
    height: 95vh;
    width: 95%;
  }
  .home_btn1 {
    margin-left: 0px;
    margin-top: 15px;
  }
  .home_btn_div {
    display: flex;
    flex-direction: column;
  }
  .home_main {
    background-image: url("../assets/images/section6mobileimagebg.js.jpg");
    height: 100vh;
    background-attachment: scroll;
  }
}
@media only screen and (max-width: 450px) {
  .highlightedTexts {
    /* background-color: rgba(255, 255, 255, 0.4); */
    font-size: 26px;
    font-weight: 700;
  }
}
@media only screen and (max-width: 380px) {
  .home_title {
    font-size: 30px;
    line-height: 42px;
  }
  .highlightedText {
    /* background-color: rgba(255, 255, 255, 0.4); */
    font-size: 23px;
    font-weight: 700;
  }
}
