.benifit-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100;
  margin: 0px auto;
}
.marginStyling {
  /* margin-top: 100px; */
  margin-bottom: 50px;
}
.benifit-topText {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  /* Primary blue */

  color: #25abdf;
}
.benifit-title {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 52px;
  /* identical to box height */

  text-transform: capitalize;

  /* Grey 1 */

  color: #3a3a3a;
}
.benifit-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  /* text */
  width: 774px;

  color: #6a7c8a;
}
.wholeArrayWrapper {
  display: flex;
  justify-content: space-between;
}
.benifits-arraywrapper {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 350px;
  border-radius: 5px;
  border: 1px solid #eaf2f5;
}

.benifits-arrayTitle {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #3a3a3a;
}

.benifits-arraySubtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 147.4%;
  color: #6a7c8a;
}
.benifits-arraywrapper:hover {
  cursor: pointer;
  background-color: #25abdf;
  border: none;
  color: #ffffff !important;
}
.benifits-arraywrapper:hover > p {
  color: #ffffff !important;
}

@media (min-width: 320px) and (max-width: 768px) {
  .benifits-arraywrapper {
    /* width: 100%; */
    border: 1px solid #25abdf;
    border-radius: 5px;
  }
  .wholeArrayWrapper {
    flex-direction: column;
    gap: 1.5rem;
  }
  .wholeArrayWrapper {
    justify-content: center;
    align-items: center;
  }
  .marginStyling {
    margin-top: 10px;
    /* margin-bottom: 10px; */
  }
  .benifit-subtitle {
    width: auto;
  }
}
