.privacy {
  padding: 35px 65px;
  /* background-color: #F9FBFC; */
  /* border: 1px solid #BEE6F5; */
  border-radius: 0px 12px 12px 12px;
  margin-bottom: 30px !important;
}
.privacy-title {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
  /* identical to box height */

  /* Headings */

  color: #263034;
}
.privacy-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #555555;
}
.privacy-h3 {
  font-family: Objektiv-Mk1;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 26px;
  color: #3a3a3a;
}

ul {
  /* list-style: none; */
}

/* .privacy ul li::before {
  content: "\2022";
  color: #25ABDF;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
} */

@media only screen and (max-width: 600px) {
  .privacy {
    padding: 25px 35px;
    width: 90%;
    margin: 0 auto !important;
    margin-bottom: 30px !important;
  }
}
