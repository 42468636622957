.faaq_main{
    width: 100%;
    padding-top: 160px;
    padding-bottom: 30px;
  
}
.fad_data{

max-width: 1120px;
width: 100%;
margin: 0px auto;

border-radius: 5px;
padding: 20px 20px;
}
.faq_headaing{
    font-family: objektiv-mk1 , sans-serif;
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 52px;
padding-bottom: 30px;
border-bottom: 1px solid #25ABDF;
}
.accordion-item {
border: none !important;
  border-bottom: 1px solid #949494 !important;
  padding: 9px 0px;
  
}
/* .accordion-button:not(.collapsed)::after {
    transform: rotate(-542deg) !important;
}
.accordion-button::after {
   
    transform: rotate(-89deg) !important;
} */
.accordion-item:first-of-type .accordion-button {
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;



color: #3A3A3A;

    box-shadow: none;
    outline: none;
    border: none !important;
    
}
.accordion-button {
    
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    padding: 20px 10px;
  
}
.accordion-body {
    padding: 1rem 1.25rem;
    background: white;
}
.accordion-button {
    background-color: white;
    box-shadow: none;
    outline: none;
    border: none !important;
    font-family: 'Poppins' !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 18px !important;
line-height: 27px !important;
color: #3A3A3A !important;
}
.accordion-body {
    padding: 10px;
   
    font-size: 30px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;


color: #555555;
}
.accordion-button:not(.collapsed) {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    background-color: white;
    color: #3A3A3A;
    text-decoration: underline;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.new_foter_accoerdiaN{
    padding: 0px 0px !important;
    border-bottom: none !important;
}